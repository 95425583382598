import * as React from 'react'
import Box from '@mui/material/Box'
import { useMemo } from 'react'

import { useTranslation } from '@hooks'
import { useGlobalRanking } from '@features/Rankings/hooks/useGlobalRanking'
import {
  CustomButton,
  Loading,
  PaginatedTable,
  TableActions,
  TableColumns,
  TextCaptionBold,
  TextH2Bold,
  TextH3Bold,
} from '@components'
import { DatesRange, RankingGlobal, RankingGlobalComparator, RankingType } from '@features/Rankings/types'
import { colors } from '@styles'
import { Images } from '@images'
import { GlobalRankingWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const GlobalRankingWidget = (props: Omit<GlobalRankingWidgetType, 'type'>) => {
  const { title, actionTarget, actionName } = props
  const { t } = useTranslation()
  const { data, isLoading } = useGlobalRanking(DatesRange.Total, RankingType.Global)
  const columns: (TableColumns<RankingGlobal> | TableActions<RankingGlobal>)[] = [
    {
      width: '10%',
      field: 'position',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.position'),
      getValue: item => {
        return item.position
      },
      renderCell: (_item, value) => {
        return (
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ minHeight: 40, justifyContent: 'center', paddingLeft: 1 }}
          >
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
    },
    {
      width: '65%',
      field: 'name',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.name'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => `${item.name || ''}`,
    },

    {
      width: '25%',
      field: 'totalPoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.totalPoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.totalPoints ?? '-'
      },
    },
  ]
  const rankingData = useMemo(() => {
    if (data) {
      data.data = data.data.slice(0, 5)
    }

    return data
  }, [data])

  const onClick = actionTarget ? () => window.location.replace(actionTarget) : undefined

  return (
    <WidgetContent>
      <Box sx={{ width: '100%', position: 'relative' }} mb={2}>
        <img
          src={Images.WidgetRankingImage}
          style={{ border: `1px solid ${colors.grayscale['30'].background}`, borderRadius: 12, width: '100%' }}
          alt={'widget-ranking-header'}
        />
        {!!title && (
          <Box sx={{ position: 'absolute', padding: 2, bottom: 0 }}>
            <TextH2Bold>{title}</TextH2Bold>
          </Box>
        )}
      </Box>
      {isLoading && <Loading />}
      {!isLoading && rankingData?.data && rankingData?.data.length == 0 && (
        <Box alignSelf={'center'} mt={2}>
          <TextH3Bold>{t('ranking.empty')} </TextH3Bold>
        </Box>
      )}
      {rankingData?.data && rankingData?.data.length > 0 && (
        <PaginatedTable<RankingGlobal>
          hidePagination={true}
          columns={columns}
          onChangePage={() => {}}
          data={rankingData}
          extraItemComparator={RankingGlobalComparator}
          borderColor={'#C7C7C7'}
          minWidth={'auto'}
        />
      )}
      {rankingData?.data && rankingData?.data.length > 0 && !!actionName && !!onClick && (
        <CustomButton
          variant={'secondary'}
          label={actionName}
          sx={{ mt: 2, fontWeight: 700, fontSize: 18 }}
          onClick={onClick}
        />
      )}
    </WidgetContent>
  )
}

export { GlobalRankingWidget }
