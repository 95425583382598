import * as React from 'react'

import { VideoWidgetType } from '@features/Widgets/types'

import { WidgetContent } from '../WidgetContent'

const VideoWidget = (props: Omit<VideoWidgetType, 'type'>) => {
  const { imageUrl, videoUrl } = props

  return (
    <WidgetContent sx={theme => ({ padding: 0, overflow: 'hidden', height: theme.spacing(50) })}>
      <video poster={imageUrl} width={'100%'} height={'100%'} controls style={{ backgroundColor: '#000000' }}>
        <source src={videoUrl} />
      </video>
    </WidgetContent>
  )
}

export { VideoWidget }
