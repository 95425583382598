import Box from '@mui/material/Box'
import * as React from 'react'

import { AppBase } from '@features/AppBase'
import { useTranslation } from '@hooks'
import { Images } from '@images'
import { Content, Title } from '@components'
import { PaymentsHistory } from '@features/Coins/components/PaymentsHistory'

const PaymentsMain = () => {
  const { t } = useTranslation()

  return (
    <AppBase requireActiveSession={true} backgroundImage={Images.WalletBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('payments.title')} />
        <Content
          flexDirection={{ md: 'row', xs: 'column' }}
          sx={{ alignItems: 'flex-start', width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <PaymentsHistory />
        </Content>
      </Box>
    </AppBase>
  )
}

export { PaymentsMain }
